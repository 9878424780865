/**
 =========================================================
 * Material Kit 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props
// import PropTypes from "prop-types";

// language imports
import MKBox from "../MKBox";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKTypography from "../MKTypography";
import macbook from "../../assets/images/logo.png";
import { forwardRef } from "react";
import PropTypes from "prop-types";
import MKButton from "../MKButton";
import MMLocalization from "../MMLocalization";

const pageContents = MMLocalization("landing-page");
const MMDarkHeader = forwardRef(({ title, description, buttons, ...rest }, ref) => (
  <MKBox component="section" pt={6} my={6}>
    <MKBox
      {...rest}
      ref={ref}
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      pt={6}
      my={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container alignItems="center">
          <Grid item md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
            <MKTypography variant="h4" color="white">
              {title}
            </MKTypography>
            <MKTypography variant="body2" color="white" mb={3}>
              {description}
            </MKTypography>
          </Grid>
          <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
            <MKBox position="relative">
              <MKBox component="img" src={macbook} alt="macbook" width="100%" />
            </MKBox>
          </Grid>
        </Grid>
        <Grid container alignItems="center">
          <Grid item md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
            {buttons ? (
              <Grid container spacing={1}>
                <Grid item xs={12} xl={6}>
                  <a href={pageContents["teaser_action_btn_1"]["action"]}>
                    <MKButton variant="gradient" color="info" sx={{ height: "100%" }}>
                      {pageContents["teaser_action_btn_1"]["name"]}
                    </MKButton>
                  </a>
                </Grid>
                <Grid item xs={12} xl={6}>
                  <a href={pageContents["teaser_action_btn_2"]["action"]}>
                    <MKButton variant="gradient" color="success" sx={{ height: "100%" }}>
                      {pageContents["teaser_action_btn_2"]["name"]}
                    </MKButton>
                  </a>
                </Grid>
              </Grid>
            ) : (
              <div></div>
            )}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  </MKBox>
));

// Setting default values for the props of MKAlert
MMDarkHeader.defaultProps = {
  title: "Title",
  description: "Description",
  buttons: false,
};

MMDarkHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttons: PropTypes.bool,
};

export default MMDarkHeader;
