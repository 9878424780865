/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// language imports
import de from "config/page_contents/de/content.json";
import en from "config/page_contents/en/content.json";

function MMLocalization(section) {
  let res = [];

  let language = "de"; //german is default
  let lang = localStorage.getItem("language");
  if (lang && lang !== "") {
    language = lang;
  }

  switch (language) {
    case "de":
      res = de[section];
      break;
    case "en":
      res = en[section];
      break;
    default:
      res = en[section];
      break;
  }

  return res;
}

// Setting default values for the props of MKAlert
MMLocalization.defaultProps = {
  section: "landing-page",
};

// Typechecking props of the MKAlert
MMLocalization.propTypes = {
  section: PropTypes.oneOf(["landing-page"]),
};

export default MMLocalization;
