import axios from "axios";

import config from "config/config.json";

export function sendNewReach(name, contact, subject, message) {
  return postFunc(config["mm_backend_new_reach_add_endpoint"], {
    name: name,
    mail: contact,
    subject: subject,
    message: message,
  });
}

let postFunc = function (url, payload) {
  const session_token = localStorage.getItem("session_token");
  return axios
    .post(config["mm_backend_api_base_url"] + url, payload, {
      headers: {
        "session-token": session_token,
      },
    })
    .then((response) => {
      console.log("Success ========>", response.data);
      return response;
    })
    .catch((error) => {
      console.log("Error ========>", error);
    });
};

// function getFunc(url) {
//   const session_token = localStorage.getItem("session_token");
//   axios
//     .get(config["mm_backend_api_base_url"] + url, {
//       headers: {
//         "session-token": session_token
//       },
//     })
//     .then((response) => {
//       if (response.data != null) {
//         return response;
//       }
//   })
//     .catch((error) => {
//       console.log("Error ========>", error);
//   })
//
//   return null
// }
