/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "../../components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";

import DefaultFooter from "../../examples/Footers/DefaultFooter";

// Routes
import routes from "../../routes";
import footerRoutes from "../../footer.routes";
import MMLocalization from "../../components/MMLocalization";
import MMDarkHeader from "../../components/MMDarkHeader";
import Contact from "../LandingPages/LandingPage/sections/Contact";

function ImprintPage() {
  const pageContents = MMLocalization("imprint");
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/login",
          label: "login",
          color: "default",
        }}
        sticky
      />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MMDarkHeader
          title={pageContents["title"]}
          description={pageContents["description"]}
          buttons={true}
        />
        <Contact />
      </Card>
      <MKBox pt={0} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ImprintPage;
