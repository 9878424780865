/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "../../components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";

import DefaultFooter from "../../examples/Footers/DefaultFooter";

// Routes
import routes from "../../routes";
import footerRoutes from "../../footer.routes";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKTypography from "../../components/MKTypography";
import MMLocalization from "../../components/MMLocalization";
import MMDarkHeader from "../../components/MMDarkHeader";
import ContactNowPrompt from "../ServicesPage/sections/ContactNowPrompt";
import RotatingCard from "../../examples/Cards/RotatingCard";
import RotatingCardFront from "../../examples/Cards/RotatingCard/RotatingCardFront";
import bgFront from "../../assets/images/rotating-card-bg-front.jpeg";
import RotatingCardBack from "../../examples/Cards/RotatingCard/RotatingCardBack";
import bgBack from "../../assets/images/rotating-card-bg-back.jpeg";
import DefaultInfoCard from "../../examples/Cards/InfoCards/DefaultInfoCard";

function CVPage() {
  const pageContents = MMLocalization("cv");
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/login",
          label: "login",
          color: "default",
        }}
        sticky
      />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MMDarkHeader title={pageContents["title"]} description={pageContents["description"]} />
        <MKBox component="section" py={6} my={6}>
          <Container>
            <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
              <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
                <RotatingCard>
                  <RotatingCardFront
                    image={bgFront}
                    icon="touch_app"
                    title={pageContents["card_front_title"]}
                    description={pageContents["card_front_description"]}
                  />
                  <RotatingCardBack
                    image={bgBack}
                    title={pageContents["card_back_title"]}
                    description={pageContents["card_back_description"]}
                  />
                </RotatingCard>
              </Grid>
              <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <DefaultInfoCard
                      icon="content_copy"
                      title="Full Documentation"
                      description="Built by developers for developers. Check the foundation and you will find
                    everything inside our documentation."
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DefaultInfoCard
                      icon="flip_to_front"
                      title="MUI Ready"
                      description="The world's most popular react components library for building user interfaces."
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
                  <Grid item xs={12} md={6}>
                    <DefaultInfoCard
                      icon="price_change"
                      title="Save Time & Money"
                      description="Creating your design from scratch with dedicated designers can be very expensive. Start with our Design System."
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DefaultInfoCard
                      icon="devices"
                      title="Fully Responsive"
                      description="Regardless of the screen size, the website content will naturally fit the given resolution."
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
        <MKBox component="section" pt={0} my={6}>
          <Container>
            <Grid container alignItems="center">
              <Grid item>
                <MKTypography variant="h4">asdasd</MKTypography>
                <MKTypography variant="body2" color="text" mb={3}>
                  asdasd
                </MKTypography>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
        <ContactNowPrompt />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default CVPage;
