// @mui icons
// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo.png";
import { LinkedIn } from "@mui/icons-material";
import MMLocalization from "./components/MMLocalization";

const date = new Date().getFullYear();

const contentData = MMLocalization("footer");

export default {
  brand: {
    name: "mehlichmeyer.de",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <LinkedIn />,
      link: "https://www.linkedin.com/mehlichmeyer/",
    },
  ],
  menus: [
    {
      name: contentData["menu_1_title"],
      items: [
        {
          name: contentData["menu_1_entries"][0]["name"],
          href: contentData["menu_1_entries"][0]["href"],
        },
        {
          name: contentData["menu_1_entries"][1]["name"],
          href: contentData["menu_1_entries"][1]["href"],
        },
        {
          name: contentData["menu_1_entries"][2]["name"],
          href: contentData["menu_1_entries"][2]["href"],
        },
        {
          name: contentData["menu_1_entries"][3]["name"],
          href: contentData["menu_1_entries"][3]["href"],
        },
      ],
    },
    {
      name: "Shops",
      items: [
        { name: "Konark", href: "https://konark-shop.de" },
        // { name: "Snusleep", href: "https://snusleep.com" },
        // { name: "Heika24", href: "https://heika24.com" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      Copyright &copy; {date} mehlichmeyer.de
      <MKTypography
        component="a"
        href="https://www.mehlichmeyer.de"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      ></MKTypography>
      .
    </MKTypography>
  ),
};
