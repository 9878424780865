/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "../../components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";

import DefaultFooter from "../../examples/Footers/DefaultFooter";

// Routes
import routes from "../../routes";
import footerRoutes from "../../footer.routes";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKTypography from "../../components/MKTypography";
import MMLocalization from "../../components/MMLocalization";
import ContactNowPrompt from "./sections/ContactNowPrompt";
import Skills from "./sections/Skills";
import MMDarkHeader from "../../components/MMDarkHeader";

function ServicesPage() {
  const pageContents = MMLocalization("services");
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/login",
          label: "login",
          color: "default",
        }}
        sticky
      />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <MMDarkHeader title={pageContents["title"]} description={pageContents["description"]} />
        <MKBox component="section" pt={0} my={6}>
          {pageContents["sections"].map((entry, i) => {
            return (
              <Container key={i}>
                <Grid container alignItems="center">
                  <Grid item>
                    <MKTypography variant="h4">{entry["header"]}</MKTypography>
                    <MKTypography variant="body2" color="text" mb={3}>
                      {entry["content"]}
                    </MKTypography>
                  </Grid>
                </Grid>
              </Container>
            );
          })}
        </MKBox>
        <Skills />
        <ContactNowPrompt />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default ServicesPage;
