/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";
import MMLocalization from "../../../components/MMLocalization";
import ContactNowPrompt from "./ContactNowPrompt";
function Skills() {
  const pageContents = MMLocalization("skills");
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              {pageContents["title"]}
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              {pageContents["description"]}
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {pageContents["entries"].map((entry, i) => {
            return (
              <Grid item xs={12} lg={6} key={i}>
                <MKBox mb={1}>
                  <HorizontalTeamCard
                    image={entry["image_link"]}
                    name={entry["name"]}
                    position={{ color: "info", label: entry["short_description"] }}
                    description={entry["description"]}
                  />
                </MKBox>
              </Grid>
            );
          })}
        </Grid>
        <ContactNowPrompt />
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              {pageContents["technology_title"]}
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              {pageContents["technology_description"]}
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {pageContents["technology_entries"].map((entry, i) => {
            return (
              <Grid item xs={12} lg={6} key={i}>
                <MKBox mb={1}>
                  <HorizontalTeamCard
                    image={entry["image_link"]}
                    name={entry["name"]}
                    position={{ color: "info", label: entry["short_description"] }}
                    description={entry["description"]}
                  />
                </MKBox>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Skills;
