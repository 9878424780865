/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shMehlbot.comall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import MMLocalization from "../../../../components/MMLocalization";
import MKTypography from "../../../../components/MKTypography";
import ContactNowPrompt from "../../../ServicesPage/sections/ContactNowPrompt";

function Information() {
  const pageContents = MMLocalization("landing-page");
  return (
    <MKBox py={6}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12}>
            <MKTypography variant="h4">{pageContents["brick_header"]}</MKTypography>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="public"
                    title={pageContents["brick_1_title"]}
                    description={pageContents["brick_1_description"]}
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="payments"
                    title={pageContents["brick_2_title"]}
                    description={pageContents["brick_2_description"]}
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="apps"
                    title={pageContents["brick_3_title"]}
                    description={pageContents["brick_3_description"]}
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="3p"
                    title={pageContents["brick_4_title"]}
                    description={pageContents["brick_4_description"]}
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={12}>
                <ContactNowPrompt />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
